import React, { Component } from 'react'
import { Row, Col, Button, FormGroup, FormText, Label, Input } from 'reactstrap'
import moment from 'moment'
import SimpleMDE from 'react-simplemde-editor'
import Select from 'react-select'
import uuidv4 from 'uuid/v4'

import { fstore, storage } from '../../../../config/firebase-config'
import { OverlayLoader } from '../../partials/OverlayLoader'
import { arrayBucketRefToDownloadLinks } from '../../../../helpers/Firebase-Helper'

class TourEditor extends Component {
  state = {
    isLoading: false,
    showOverlay: false,
    tourPriority: '',
    tourName: '',
    tourSubtitle: '',
    articleData: '',
    selectedOptions: undefined,
    isPublished: true,
    tourType: '',
    labelList: [],
    fileImage: [], // array of type files
    tourImagesBucket: [], // array of img REF to be uploaded
    tobeDeletedLinks: [], // array of img REF to be deleted
    imgLinks: [], // array of img LINKS
    tourTypeList: [],
  }

  normalizeLabel = (array, normalize = true) => {
    const processedArray = []
    if (array)
      if (normalize)
        array.forEach(element => {
          processedArray.push(element.value)
        })
      else
        array.forEach(element => {
          processedArray.push({ value: element, label: element })
        })
    return processedArray
  }

  handleSubmitData = value => {
    this.setState({ isLoading: true })

    //set the data structure but not for the image links
    const {
      tourPriority,
      tourName,
      tourSubtitle,
      tourType,
      articleData,
      isPublished,
      selectedOptions,
    } = this.state
    const putData = {
      tourPriority,
      tourName,
      tourSubtitle,
      tourType,
      isPublished,
      tourDesc: articleData,
      label: this.normalizeLabel(selectedOptions),
      lastEdit: moment().format('MMMM Do YYYY, h:mm:ss a'),
    }
    console.log(putData)

    const {
      match: {
        params: { id },
      },
    } = this.props

    const uploadPromises = []
    let docPointer = null
    let docId = null
    if (this.props.pageType === 'EDIT') {
      docPointer = fstore
        .collection('tours')
        .doc(id)
        .set(putData)
    } else {
      docPointer = fstore.collection('tours').add(putData)
    }

    //store the data to firestore
    docPointer
      .then(ref => {
        console.log('ref = ', ref)
        docId = ref ? ref.id : id
        console.log('ID = ', id, 'DOCID = ', docId)

        //point to bucket reference folder based on firestore data
        const storageRef = storage.ref(`tours/${docId}/`)
        console.log(storageRef.fullPath)

        //upload all images
        if (Array.isArray(this.state.fileImage) && this.state.fileImage.length > 0)
          this.state.fileImage.forEach(img => {
            console.log('Uploading file ...')
            const imgName = `article-${docId}-${uuidv4()}.jpg`
            const uploadTask = storageRef
              .child(imgName)
              .put(img)
              .then(res => {
                console.log('Success upload image: ', res)
                return storageRef.child(imgName).fullPath
              })
              .catch(err => console.log('Error upload individual file: ', err.message))
            uploadPromises.push(uploadTask)
          })
        return Promise.all(uploadPromises)
      })
      .then(refArray => {
        if (Array.isArray(this.state.fileImage) && this.state.fileImage.length > 0) {
          console.log('ALL NEW FILES HAVE BEEN UPLOADED! Promise all: ', refArray)
        } else console.log('No image need to be uploaded')

        //merge the img refPath with the newly added images
        if (Array.isArray(this.state.tourImagesBucket)) {
          this.state.tourImagesBucket.push(...refArray)
          this.setState({
            tourImagesBucket: this.state.tourImagesBucket,
          })
        } else {
          this.setState({
            tourImagesBucket: refArray,
          })
        }

        // check if something need to be deleted from storage
        let tobeDeletedRef = []
        if (this.state.tobeDeletedLinks.length > 0) {
          console.log('Processing delete')
          this.state.tobeDeletedLinks.forEach(link => {
            const fullPath = storage.refFromURL(link).fullPath
            // push deleting task promises to array
            tobeDeletedRef.push(
              storage
                .refFromURL(link)
                .delete()
                .then(() => {
                  return fullPath
                })
            )
          })
          // execute the deleting batch from storage
          return Promise.all(tobeDeletedRef)
        }
      })
      .catch(err => {
        console.log('Promise upload all images failed: ', err.message)
      })
      .then(arrayOfDeletedRef => {
        if (!Array.isArray(arrayOfDeletedRef) || arrayOfDeletedRef.length <= 0) {
          console.log('Nothing to be deleted. Array: ', arrayOfDeletedRef)
        } else {
          console.log('Array of bucket to delete promise ', arrayOfDeletedRef)
          for (let i = 0; i < this.state.tourImagesBucket.length; i++) {
            arrayOfDeletedRef.forEach(ref => {
              if (this.state.tourImagesBucket[i] === ref) {
                this.state.tourImagesBucket.splice(i, 1)
                i--
                console.log('MATCH DELETE', this.state.tourImagesBucket)
              }
            })
          }
          this.setState({
            tourImagesBucket: this.state.tourImagesBucket,
          })
        }
      })
      .catch(err => {
        console.log('Failed batch delete ', err.message)
      })
      .then(res => {
        //update image path after success upload
        console.log(this.state.tourImagesBucket, 'FSTORE BUCKET AFTER DELETE')
        return fstore
          .collection('tours')
          .doc(docId)
          .update({ tourImages: this.state.tourImagesBucket })
      })
      .catch(err => {
        console.log('Failed update fstore bucket array: ', err.message)
      })
      .then(res => {
        console.log('Success update images path: ', res)
        setTimeout(() => {
          this.props.history.push('/admin/dashboard/tours')
        }, 2000)
      })
      .catch(err => {
        console.log('Failed update image path', err.message)
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  handleInputChange = e => {
    if (e.target.type === 'file') {
      this.setState({ [e.target.name]: Array.from(e.target.files) })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  handleSelectChange = selectedOptions => {
    this.setState({
      selectedOptions: selectedOptions,
    })
  }

  //TODO: pagetype edit or create new
  componentDidMount() {
    fstore
      .collection('type')
      .doc('tour')
      .get()
      .then(res => {
        if (res.data().data) {
          this.setState({ tourTypeList: res.data().data })
        }
      })

    fstore
      .collection('type')
      .doc('label')
      .get()
      .then(res => {
        this.setState({ labelList: this.normalizeLabel(res.data().data, false) })
      })

    const { pageType } = this.props
    if (pageType === 'ADD') {
      // this.fetchData(this.props.params);
    } else if (pageType === 'EDIT') {
      this.setState({ showOverlay: true })
      const {
        match: {
          params: { id: docId },
        },
      } = this.props

      // fetching data from firestore
      fstore
        .collection('tours')
        .doc(docId)
        .get()
        .then(res => {
          const {
            tourPriority,
            tourName,
            tourSubtitle,
            tourType,
            tourDesc,
            label,
            isPublished,
            tourImages,
          } = res.data()
          this.setState({
            tourPriority,
            tourName,
            tourSubtitle,
            articleData: tourDesc,
            selectedOptions: this.normalizeLabel(label, false),
            isPublished,
            tourImagesBucket: tourImages,
            tourType,
          })

          //fetch all img download links
          arrayBucketRefToDownloadLinks(tourImages).then(arr => {
            if (arr.length > 0) {
              for (let i = 0; i < arr.length; i++) {
                if (arr[i] === '') {
                  arr.splice(i, 1)
                  i--
                }
              }
            }
            this.setState({ imgLinks: arr })
            console.log(arr)
          })
          console.log(res.data())
        })
        .catch(err => {
          console.log('Failed to fetch', err.message)
        })
        .finally(() => {
          this.setState({ showOverlay: false })
        })
    } else {
      console.log('Unknown Page Type')
    }
  }

  render() {
    return (
      <div className="tour-editor" id="tour-editor">
        <OverlayLoader isShow={this.state.showOverlay} />
        <h3 className="text-center">{`${this.props.pageType === 'EDIT' ? 'Edit' : 'New'} Tour`}</h3>
        <Row className="justify-content-center py-3">
          <Col lg="8">
            <FormGroup>
              <Label for="tourPriority">Tour Priority Scale</Label>
              <Input
                onChange={this.handleInputChange}
                type="number"
                name="tourPriority"
                id="tourPriority"
                placeholder="The lower number, the higher priority is"
                value={this.state.tourPriority}
              />
            </FormGroup>
            <FormGroup>
              <Label for="tourName">Tour Name</Label>
              <Input
                onChange={this.handleInputChange}
                type="text"
                name="tourName"
                id="tourName"
                placeholder="Name of the tour"
                value={this.state.tourName}
              />
            </FormGroup>
            <FormGroup>
              <Label for="tourSubtitle">Tour Subtitle</Label>
              <Input
                onChange={this.handleInputChange}
                type="text"
                name="tourSubtitle"
                id="tourSubtitle"
                placeholder="Will be shown on the card"
                value={this.state.tourSubtitle}
              />
            </FormGroup>
            <FormGroup>
              <Label for="tourType">Tour Type</Label>
              <Input
                name="tourType"
                type="select"
                onChange={this.handleInputChange}
                value={this.state.tourType}>
                <option defaultValue hidden />
                {this.state.tourTypeList.map((d, key) => (
                  <option key={key} value={d}>
                    {d}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="tourLabel">Tour Label</Label>
              <Select
                name="selectedOptions"
                options={this.state.labelList}
                onChange={this.handleSelectChange}
                value={this.state.selectedOptions}
                isMulti={true}
                placeholder="beach, forest, animal, ..."
              />
            </FormGroup>
            <FormGroup>
              <Label for="isPublished">Publish after save?</Label>
              <Input
                onChange={this.handleInputChange}
                name="isPublished"
                type="select"
                className="col-sm-2"
                value={this.state.isPublished}>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="imageInput">Tour Images</Label>
              <Input
                type="file"
                name="fileImage"
                id="imageInput"
                multiple
                onChange={this.handleInputChange}
              />
              <FormText color="muted">
                Select and upload your image here. You can upload up to 10 images, each image must
                not has size more than 3Mb.
              </FormText>
            </FormGroup>

            <div className="img-upload-preview d-flex flex-wrap">
              {this.state.fileImage.map((d, i) => {
                const url = URL.createObjectURL(d)
                return <img className="mr-2 mb-2" key={i} src={url} alt="be-uploaded" />
              })}
            </div>

            {this.props.pageType === 'EDIT' && this.state.imgLinks && (
              <div>
                <Label>Already Uploaded Images:</Label>
                <hr />
                <div className="img-upload-preview d-flex flex-wrap">
                  {this.state.imgLinks.map((img, key) => {
                    return (
                      <img
                        className="mr-2 mb-2 uploaded"
                        src={img}
                        key={key}
                        alt="uploaded"
                        onClick={() => {
                          //removing images from preview
                          for (let i = 0; i < this.state.imgLinks.length; i++) {
                            if (this.state.imgLinks[i] === img) {
                              this.state.imgLinks.splice(i, 1)
                              this.state.tobeDeletedLinks.push(img)
                              this.setState({
                                imgLinks: this.state.imgLinks,
                                tobeDeletedLinks: this.state.tobeDeletedLinks,
                              })
                            }
                          }
                        }}
                      />
                    )
                  })}
                </div>
              </div>
            )}

            <SimpleMDE
              id="tour-article-editor"
              label="Tour Article Editor"
              value={this.state.articleData}
              onChange={value => this.setState({ articleData: value })}
              options={{
                hideIcons: ['guide', 'fullscreen', 'side-by-side'],
              }}
            />
            <div className="d-flex">
              <Button
                onClick={this.handleSubmitData}
                className="ml-auto site-primary on-white"
                disabled={this.state.isLoading}>
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default TourEditor
