import React, { Component } from 'react'
import _ from 'lodash'
import { fstore, storage } from '../../config/firebase-config'

const ClientDataContext = React.createContext()

class ClientDataProvider extends Component {
  state = {
    welcomeMessages: [],
    destinations: [],
    tours: [],
    finishedFetch: false,
    animatedLoader: false,
  }

  componentDidMount() {
    const fetchDestination = fstore
      .collection('destinations')
      .get()
      .then(snapshots => {
        if (!snapshots.empty) {
          const destinations = []
          snapshots.forEach(snap => {
            const data = snap.data()
            const imagePromises = data.destImages.map(d => storage.ref(d).getDownloadURL())
            destinations.push(
              Promise.all(imagePromises).then(images => {
                return {
                  id: snap.id,
                  title: data.destName,
                  desc: data.destDesc,
                  label: data.label,
                  images: images,
                  isPublished: data.isPublished,
                }
              })
            )
          })
          Promise.all(destinations).then(destinations => {
            this.setState({ destinations })
          })
        }
      })

    const fetchTours = fstore
      .collection('tours')
      .get()
      .then(snapshots => {
        if (!snapshots.empty) {
          const tours = []
          snapshots.forEach(snap => {
            const data = snap.data()
            const imagePromises = data.tourImages.map(d => storage.ref(d).getDownloadURL())
            tours.push(
              Promise.all(imagePromises).then(images => {
                return {
                  id: snap.id,
                  priority: data.tourPriority || 10,
                  title: data.tourName,
                  desc: data.tourDesc,
                  type: data.tourType,
                  label: data.label,
                  images: images,
                  isPublished: data.isPublished,
                }
              })
            )
          })
          Promise.all(tours).then(tours => {
            this.setState({ tours })
          })
        }
      })

    const fetchMessage = fstore
      .collection('welcome-messages')
      .get()
      .then(snapshots => {
        const WelcomePromises = []
        if (!snapshots.empty) {
          snapshots.forEach(snap => {
            const data = snap.data()
            WelcomePromises.push(
              storage
                .ref(data.imgPath)
                .getDownloadURL()
                .then(imgLink => {
                  return {
                    priority: data.priority,
                    title: data.messageName,
                    caption: data.messageCaption,
                    src: imgLink,
                  }
                })
            )
          })
        }
        return Promise.all(WelcomePromises)
      })
      .then(welcomeMessages => {
        this.setState({ welcomeMessages: _.orderBy(welcomeMessages, ['priority', 'title'], 'asc') })
      })
      .catch(e => console.log('Welcome failed', e.message))

    Promise.all([fetchDestination, fetchTours, fetchMessage]).then(res => {
      this.setState({
        finishedFetch: true,
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.finishedFetch === false && this.state.finishedFetch === true) {
      setTimeout(() => {
        this.setState({ animatedLoader: true })
      }, 10000)
    }
  }

  render() {
    return (
      <ClientDataContext.Provider
        value={{
          welcomeMessages: this.state.welcomeMessages,
          destinations: this.state.destinations,
          tours: this.state.tours,
          finishedFetch: this.state.finishedFetch,
          animatedLoader: this.state.animatedLoader,
        }}>
        {this.props.children}
      </ClientDataContext.Provider>
    )
  }
}

const ClientDataConsumerHOC = WrappedComponent => {
  const hoc = props => (
    <ClientDataContext.Consumer>
      {clientData => <WrappedComponent {...props} {...clientData} />}
    </ClientDataContext.Consumer>
  )
  return hoc
}

const ClientDataConsumer = ClientDataContext.Consumer

export { ClientDataConsumer, ClientDataProvider, ClientDataConsumerHOC }
