import React, { Component } from 'react'
import { Table, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { fstore } from '../../../../config/firebase-config'

const DataRecord = ({ snap, index, deleteItem }) => {
  const { destName, label, isPublished, lastEdit = '' } = snap.data()
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{destName}</td>
      <td>
        {label.map(d => {
          return `${d} `
        })}
      </td>
      <td>{lastEdit}</td>
      <td>{isPublished ? 'Yes' : 'No'}</td>
      <td>
        <Link to={`/admin/dashboard/destinations/edit/${snap.id}`}>
          <button className="btn site-link">Edit</button>
        </Link>
        <span className="mx-2">/</span>
        <button
          className="btn site-link"
          onClick={() => {
            const confirmDelete = window.confirm('Are you sure ?')
            if (confirmDelete) {
              snap.ref.delete().then(() => {
                deleteItem(snap.id)
              })
            }
          }}>
          Delete
        </button>
      </td>
    </tr>
  )
}

class DestinationList extends Component {
  state = { destinations: [] }
  deleteItem = refId => {
    if (refId) {
      _.remove(this.state.destinations, { id: refId })
      this.setState({
        destinations: this.state.destinations,
      })
    }
  }

  componentDidMount() {
    fstore
      .collection('destinations')
      .get()
      .then(snapshots => {
        console.log('Snapshots: ', snapshots)
        const destinations = []
        snapshots.forEach(snap => {
          destinations.push(snap)
        })
        this.setState({ destinations })
      })
      .catch(err => console.log(err))
  }

  render() {
    return (
      <div>
        <h3 className="text-center">List of Destinations</h3>
        <Link className="link-white" to="/admin/dashboard/destinations/add">
          <Button className="btn site-primary on-white my-2">+New Destination</Button>
        </Link>

        <Table striped hover>
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Label</th>
              <th>Last Edit</th>
              <th>Published</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.destinations.map((data, key) => {
              return <DataRecord index={key} snap={data} key={key} deleteItem={this.deleteItem} />
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default DestinationList
