import { storage } from '../config/firebase-config'

const arrayBucketRefToDownloadLinks = (arrayOfBucketRefs = []) => {
  let arrayOfDownloadLinks = []
  arrayOfBucketRefs.forEach(ref => {
    arrayOfDownloadLinks.push(
      storage
        .ref(ref)
        .getDownloadURL()
        .then(url => url)
        .catch(err => '')
    )
  })

  return Promise.all(arrayOfDownloadLinks)
}

export { arrayBucketRefToDownloadLinks }
