import React from 'react'
import { Switch, Route } from 'react-router-dom'

import TourList from './../../pages/tours/TourList'
import DestinationList from './../../pages/destinations/DestinationList'
import NotFound from './../../../pages/NotFound'
import TourEditor from './../../pages/tours/TourEditor'
import DestinationEditor from './../../pages/destinations/DestinationEditor'
import TypeManager from './../../pages/type-manager/TypeManager'
import RequestLists from './../../pages/requests/RequestList'
import WelcomeMessage from './../../pages/welcome-message/WelcomeMessage'
import WelcomeMessageEditor from './../../pages/welcome-message/WelcomeMessageEditor'

const DashboardRouteList = () => (
  <Switch>
    <Route path="/admin/dashboard/tours" exact render={() => <TourList />} />
    <Route
      path="/admin/dashboard/tours/add"
      exact
      render={props => <TourEditor pageType="ADD" {...props} />}
    />
    <Route
      path="/admin/dashboard/tours/edit/:id"
      exact
      render={props => <TourEditor pageType="EDIT" {...props} />}
    />
    <Route path="/admin/dashboard/destinations" exact render={() => <DestinationList />} />
    <Route
      path="/admin/dashboard/destinations/add"
      exact
      render={props => <DestinationEditor pageType="ADD" {...props} />}
    />
    <Route
      path="/admin/dashboard/destinations/edit/:id"
      render={props => <DestinationEditor pageType="EDIT" {...props} />}
    />
    <Route path="/admin/dashboard/manage-type" render={props => <TypeManager {...props} />} />
    <Route path="/admin/dashboard/requests" render={props => <RequestLists {...props} />} />
    <Route
      path="/admin/dashboard/welcome-message"
      exact
      render={props => <WelcomeMessage {...props} />}
    />
    <Route
      path="/admin/dashboard/welcome-message/edit/:id"
      exact
      render={props => <WelcomeMessageEditor pageType="EDIT" {...props} />}
    />
    <Route
      path="/admin/dashboard/welcome-message/new"
      exact
      render={props => <WelcomeMessageEditor pageType="NEW" {...props} />}
    />
    <Route component={NotFound} />
  </Switch>
)

export default DashboardRouteList
