import React from "react";

const NotFound = () => {
  window.scrollTo(0, 0);
  return (
    <div className="pt-page container">
      <h2>Page Not Found</h2>
      <p>Sorry but the page you are trying to access is not available</p>
    </div>
  );
};

export default NotFound;
