import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Main from "./Main";
import AdminMain from "./admin/AdminMain";
import { AuthProvider } from "./context/AuthContext";

const Root = props => {
  // Initialize Firebase
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={() => <AdminMain />} />
          <Route path="/" render={() => <Main />} />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default Root;
