import React, { Component, createContext } from "react";
import { auth } from "../../config/firebase-config";

const isFirebaseInitialized = () => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(user => {
      if (user !== {}) {
        resolve(true);
      }
    });
  });
};

class AuthProvider extends Component {
  state = {};

  signIn = (username, password) =>
    auth.signInWithEmailAndPassword(username, password);

  signOut = () => auth.signOut();

  authListener = () => {
    auth.onAuthStateChanged(user => {
      if (user) {
        this.setState({ user });
      } else {
        this.setState({ user: null });
      }
    });
  };

  componentDidMount() {
    this.authListener();
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          authStatus: this.state.authStatus,
          signIn: this.signIn,
          signOut: this.signOut,
          currentUser: this.state.user
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumerHOC = WrappedComponent => {
  const hoc = props => (
    <AuthContext.Consumer>
      {authData => <WrappedComponent {...authData} />}
    </AuthContext.Consumer>
  );
  return hoc;
};

const isEmptyObject = obj => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    } 
  }
};

export const AuthContext = createContext();

const AuthConsumer = AuthContext.Consumer;
export { isEmptyObject, AuthProvider, AuthConsumer, AuthConsumerHOC, isFirebaseInitialized };
