import React from "react";
import { Link } from "react-router-dom";

const OtherDestinations = props => {
  const { otherTour = [] } = props;
  return (
    <div className="bg-dark text-white px-3 py-2 mt-2">
      <h4>Browse Other Destinations</h4>
      <hr className="border-white" />
      <ul className="list-unstyled">
        {otherTour.map((d, i) => {
          return (
            <li className="my-2" key={i}>
              <Link className="link-white" to={d.tourUrl}>{d.tourTitle}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default OtherDestinations;
