import React, { Component } from 'react'
import Slider from 'react-slick'
import WOW from 'react-wow'
import Loader from '../../partials/LoadingContainer';

class TourDetail extends Component {
  render() {
    const { data: { title = '', desc = '', images = [] } = {}, finishedFetch = false } = this.props
    const settings = {
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return (
      <div className="">
        {!finishedFetch ? (
          <Loader />
        ) : (
          <>
            <WOW animation="fadeIn" duration="700ms" delay="400ms">
              <h3>{title}</h3>
              <hr />
            </WOW>
            <WOW animation="fadeIn" duration="700ms" delay="600ms">
              <Slider {...settings} className="mb-5">
                {images.map((d, i) => (
                  <div key={i} className="slick-item">
                    <div className="img-wrapper">
                      <img className="img-fluid" src={d} alt={i} />
                    </div>
                  </div>
                ))}
              </Slider>
              <hr />
            </WOW>
            <WOW animation="fadeIn" duration="700ms" delay="600ms">
              <div className="article">
                <p>{desc}</p>
              </div>
            </WOW>
          </>
        )}
      </div>
    )
  }
}

export default TourDetail
