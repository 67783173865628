import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

const Footer = () => {
  return (
    <div className="main-footer">
      <div className="container">
        <Row>
          <Col xs="12" md="4" className="px-4">
            <h3>Reviews - {'Bali Touren'}</h3>
            <div id="holidaycheck-widget-poi-1-29727-de_DE-m-b-1-2-1-5-0">
              <div
                id="holidaycheck_widget_placeholder-29727"
                style={{ width: '120px', padding: '5px', background: '#0058a2' }}>
                <a href="https://www.holidaycheck.de/pi/guide-made-mardika-sanur/0e2f84d8-aea5-32b8-ba5f-c2cb30ef58bd">
                  <img
                    alt="Bewertungen bei HolidayCheck"
                    src="https://www.holidaycheck.de/widgets/logo_xlarge.png"
                    border="0"
                  />
                </a>
              </div>
            </div>
          </Col>
          <Col xs="12" md="4" className="px-4">
            <h3>Navigation</h3>
            <div className="d-flex flex-row">
              <ul className="mr-5">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/book">Booking</Link>
                </li>
                <li>
                  <Link to="/tours">Tours</Link>
                </li>
                <li>
                  <Link to="/destination">Destinations</Link>
                </li>
              </ul>
              <ul className="mr-5">
                <li>
                  <Link to="/">Gallery</Link>
                </li>
                <li>
                  <Link to="/terms-of-use">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/testimonials">Testimonials</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs="12" md="4" className="px-4">
            <div className="contact-us-footer">
              <h3>Contact Us</h3>
              <div className="item-container">
                <div className="icon-item">
                  <i className="fas fa-home" />
                </div>
                <div className="content-item">
                  Banjar Biaung - Jalan Sandat, Denpasar, Bali, Indonesia
                </div>
              </div>
              <div className="item-container">
                <div className="icon-item">
                  <i className="fas fa-phone" />
                </div>
                <div className="content-item">+62 87861 462074 (Elis)</div>
              </div>
              <div className="item-container">
                <div className="icon-item">
                  <i className="fab fa-whatsapp" />
                </div>
                <div className="content-item">+62 87861 462074 (Elis)</div>
              </div>
              <div className="item-container">
                <div className="icon-item">
                  <i className="fas fa-envelope-square" />
                </div>
                <div className="content-item">tour@rin-trip.com</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Footer
