import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import BookNow from './pages/booking/BookTour'
import AllTours from './pages/tours/AllTours'
import TourDetailContainerPage from './pages/tours/TourDetailContainerPage'
import AllDestinations from './pages/destinations/AllDestinations'
import DestinationDetailContainerPage from './pages/destinations/DestinationDetailContainerPage'
import Testing from './Testing'
import About from './pages/about/About';

const RouteList = () => {
  return (
    <Switch>
      {/* <Header /> */}
      <Route exact path="/" render={() => <Home />} />
      <Route exact path="/book" render={props => <BookNow {...props} />} />
      <Route exact path="/tours" render={() => <AllTours />} />
      <Route exact path="/tour/:id" render={props => <TourDetailContainerPage {...props} />} />
      <Route exact path="/destinations" render={() => <AllDestinations />} />
      <Route
        exact
        path="/destination/:id"
        render={props => <DestinationDetailContainerPage {...props} />}
      />
      <Route exact path="/gallery" render={() => <div>Gallery</div>} />
      <Route exact path="/about" render={() => <About />} />
      <Route exact path="/testing" render={() => <Testing />} />
      <Route component={NotFound} />
      {/* <Footer /> */}
    </Switch>
  )
}

export default RouteList
