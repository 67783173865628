import React, { Component } from 'react'
import CardItemsContainer from '../../partials/card/CardItemContainer'
import { ClientDataConsumerHOC } from '../../context/ClientDataContext'

class AllDestinations extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="all-destinations pt-page">
        <div className="container">
          <h3>Browse Destinations</h3>
          <CardItemsContainer
            baseLink="destination"
            data={this.props.destinations}
            title="Explore Destinations in Bali"
          />
        </div>
      </div>
    )
  }
}

export default ClientDataConsumerHOC(AllDestinations)
