import React from 'react'
import WedgesLoaderSource from '../../../assets/loaders/wedges-loader.svg'

const WedgesLoader = () => <img src={WedgesLoaderSource} alt="wedges-loader" />

const OverlayLoader = ({ isShow = true }) => (
    <div className={`global-overlay-loader ${isShow ? '' : 'd-none'}`}>
        <WedgesLoader />
    </div>
)

export { OverlayLoader }
