import React, { Component } from 'react'
import CardItemContainer from './partials/card/CardItemContainer'
import img from '../images/test.png'
const example = [
  {
    title: 'Test',
    images: img,
    id: '189u98uf98u98fu',
    key: '1',
  },
]

class Testing extends Component {
  render() {
    return (
      <div className="home-site">
        <div className="explore d-flex flex-column align-items-center">
          <CardItemContainer
            data={example}
            baseLink={'destination'}
            title="Destination to be Explored"
          />
        </div>
      </div>
    )
  }
}

export default Testing
