import React, { Component } from 'react'
import { Row, Col, Table, Input, Label, FormGroup, Button } from 'reactstrap'

import { fstore } from '../../../../config/firebase-config'
const typeStore = fstore.collection('type')

class TypeManager extends Component {
  state = {
    labelType: [],
    tourType: [],
    labelNew: '',
    tourNew: '',
    disableSubmit: false,
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = doc => {
    let isDuplicate = false
    this.state[doc + 'Type'].forEach(d => {
      if (d === this.state[doc + 'New']) isDuplicate = true
    })

    if (!isDuplicate) {
      this.setState({ disableSubmit: true })
      const newArr = []
      newArr.push(this.state[doc + 'New'], ...this.state[doc + 'Type'])

      fstore
        .collection('type')
        .doc(doc)
        .set({ data: newArr })
        .then(res => {
          this.setState({ [doc + 'Type']: newArr })
        })
        .catch(err => console.log('Failed to add', err.message))
        .finally(() => {
          this.setState({ disableSubmit: false })
        })
    }
  }

  componentDidMount() {
    typeStore
      .get()
      .then(snapshots => {
        if (!snapshots.empty) {
          snapshots.forEach(snap => {
            this.setState({ [snap.id + 'Type']: snap.data().data })
          })
        }
      })
      .catch(err => console.log(err.message))
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <h3 className="text-center">Manage Label</h3>
            <div className="d-flex align-items-baseline ">
              <FormGroup row>
                <Label for="labelNew" sm={4} size="sm">
                  New Label:
                </Label>
                <Col sm={8}>
                  <Input
                    onChange={this.handleInputChange}
                    value={this.state.labelNew}
                    type="text"
                    name="labelNew"
                    id="labelNew"
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <Button
                disabled={this.state.disableSubmit}
                onClick={() => this.handleSubmit('label')}
                className="site-primary on-white py-1 mx-1">
                Add
              </Button>
            </div>
            <Table>
              <thead>
                <tr>
                  <td>No</td>
                  <td>Value</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {this.state.labelType.map((label, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{label}</td>
                    <td>
                      <button
                        className="btn site-link"
                        onClick={() => {
                          const confirmDelete = window.confirm('Are you sure ?')
                          if (confirmDelete) {
                            const newArr = this.state.labelType.filter(d => {
                              if (d !== label) {
                                return d
                              }
                              return undefined
                            })
                            typeStore
                              .doc('label')
                              .update({ data: newArr })
                              .then(() => {
                                this.setState({ labelType: newArr })
                              })
                          }
                        }}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>

          <Col>
            <h3 className="text-center">Manage Tour Type</h3>
            <div className="d-flex align-items-baseline ">
              <FormGroup row>
                <Label for="labelNew" sm={4} size="sm">
                  New Tour Type:
                </Label>
                <Col sm={8}>
                  <Input
                    onChange={this.handleInputChange}
                    value={this.state.tourNew}
                    type="text"
                    name="tourNew"
                    id="tourNew"
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <Button
                disabled={this.state.disableSubmit}
                onClick={() => this.handleSubmit('tour')}
                className="site-primary on-white py-1 mx-1">
                Add
              </Button>
            </div>
            <Table>
              <thead>
                <tr>
                  <td>No</td>
                  <td>Value</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {this.state.tourType.map((tour, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{tour}</td>
                    <td>
                      <button
                        className="btn site-link"
                        onClick={() => {
                          const confirmDelete = window.confirm('Are you sure ?')
                          if (confirmDelete) {
                            const newArr = this.state.tourType.filter(d => {
                              if (d !== tour) {
                                return d
                              }
                              return undefined
                            })
                            typeStore
                              .doc('tour')
                              .update({ data: newArr })
                              .then(() => {
                                this.setState({ tourType: newArr })
                              })
                          }
                        }}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    )
  }
}

export default TypeManager
