import React, { Component } from 'react'
import { Row, Col, Container, Button } from 'reactstrap'
import { slide as Menu } from 'react-burger-menu'
// https://github.com/negomi/react-burger-menu/

import { AuthConsumerHOC } from './../context/AuthContext'
import MenuItem from './partials/dashboard/MenuItems'
import Loading from './partials/BlankPageLoader'
import RouteList from './partials/dashboard/DashboardRouteList'

class Dashboard extends Component {
  state = {
    sideMenu: false,
    activeMenu: '',
  }
  sideMenuToggle = () => {
    this.setState({ sideMenu: !this.state.sideMenu })
  }
  activeMenuToggle = activeMenu => {
    this.setState({ activeMenu })
  }

  render() {
    return this.props.currentUser && this.props.currentUser.uid ? (
      <div className="admin-dashboard">
        <Menu
          className="py-4 d-md-none"
          isOpen={this.state.sideMenu}
          customBurgerIcon={false}
          customCrossIcon={false}>
          <MenuItem
            activeMenu={this.state.activeMenu}
            menuToggle={this.activeMenuToggle}
            toggle={this.sideMenuToggle}
            mobile
          />
        </Menu>
        <Container id="outerContainer" fluid>
          <Row>
            <Col className="d-none d-md-block bg-light-grey" md={3} lg={2} id="dashboardContainer">
              <div className="p-2">
                <h3>Navigation</h3>
                <hr />
                <div id="menuItem" className="d-flex flex-column">
                  <MenuItem activeMenu={this.state.activeMenu} menuToggle={this.activeMenuToggle} />
                </div>
              </div>
            </Col>

            <Col className="p-0" id="dashboardContent">
              <div className="d-flex bg-primary-site p-2 text-light">
                <Button
                  className="bg-transparent d-md-none mr-3 align-self-center border border-light"
                  onClick={this.sideMenuToggle}>
                  <i className="fas fa-bars" />
                </Button>
                <h3>Dashboard Content</h3>
                <Button size="sm" className="p-0 ml-auto" onClick={() => this.props.signOut()}>
                  Sign Out
                </Button>
                <Button
                  size="sm"
                  className="p-0 ml-2"
                  onClick={() => console.log(this.props.currentUser)}>
                  Print
                </Button>
              </div>
              <Container fluid id="content-container">
                <RouteList />
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    ) : (
      <Loading />
    )
  }
}

export default AuthConsumerHOC(Dashboard)
