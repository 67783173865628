import React from 'react'
import loader from '../../assets/loaders/elipsis-loader.svg'

const BaseOverlayLoading = props => {
  return (
    <div
      className={`base-loading d-flex flex-column align-items-center justify-content-center ${
        props.collapse ? 'collapse' : ''
      }`}>
      <h5>Warten Sie mal ...</h5>
      <img src={loader} alt="loader" />
    </div>
  )
}

export default BaseOverlayLoading
