import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Row, Col } from 'reactstrap'
import Slider from 'react-slick'
import _ from 'lodash'

import { ClientDataConsumerHOC } from '../context/ClientDataContext'
import Loader from '../partials/BaseOverlayLoading'
import CardItemContainer from '../partials/card/CardItemContainer'
import image1 from '../../images/hero_bg_2.jpg'

const items = [
  {
    title: 'Never Stop Exploring',
    src: image1,
    altText: 'Slide 1',
    caption:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga est inventore ducimus repudiandae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga est inventore ducimus repudiandae.',
  },
]

const HeroContent = props => {
  return (
    <Row className="hero-content align-items-center justify-content-center">
      <Col md="8">
        <h2>{props.title}</h2>
        <p>{props.caption}</p>
        <Link to="/book">
          <Button className="site-primary">Book Now!</Button>
        </Link>
      </Col>
    </Row>
  )
}

const heroItems = items.map((d, i) => {
  return (
    <div key={'hero-' + i}>
      <div className="hero-container static d-flex align-items-center justify-content-center">
        <div className="hero-item d-flex align-items-center justify-content-center">
          <HeroContent title={d.title} caption={d.caption} />
        </div>
      </div>
    </div>
  )
})

const welcomeMessages = props =>
  _.isArray(props) &&
  props.map((d, i) => {
    return (
      <div key={'hero-' + i} className="dynamic">
        <div
          className="hero-container d-flex align-items-center justify-content-center"
          style={{ backgroundImage: 'url(' + d.src + ')' }}>
          <div className="hero-item d-flex align-items-center justify-content-center">
            <HeroContent title={d.title} caption={d.caption} />
          </div>
        </div>
      </div>
    )
  })

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const settings = {
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    return (
      <div className="home-site">
        {!this.props.animatedLoader ? <Loader collapse={this.props.finishedFetch} /> : ''}
        <Slider {...settings} className="slick-slider" imgLoaded={this.imgLoaded}>
          {_.isArray(this.props.welcomeMessages) && this.props.welcomeMessages.length > 0
            ? welcomeMessages(this.props.welcomeMessages)
            : heroItems}
        </Slider>
        <div className="pt-4">
          <hr />
        </div>
        <div className="to-do d-flex flex-column align-items-center">
          <CardItemContainer
            data={_.slice(_.orderBy(this.props.tours, ['priority', 'name'], 'asc'), 0, 3)}
            baseLink={'tour'}
            title="Our Top Tours"
          />
          <div>
            <Link to="/tours">
              <Button className="site-primary on-white section-button">All Tours</Button>
            </Link>
          </div>
        </div>
        <div className="pt-4">
          <hr />
        </div>
        <div className="explore d-flex flex-column align-items-center">
          <CardItemContainer
            data={this.props.destinations}
            baseLink={'destination'}
            title="Destination to be Explored"
          />
          <div>
            <Link to="/destinations">
              <Button className="site-primary on-white section-button">All Destination</Button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default ClientDataConsumerHOC(Home)
