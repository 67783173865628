import React, { Component } from 'react'
import CardItemsContainer from '../../partials/card/CardItemContainer'
import { ClientDataConsumerHOC } from '../../context/ClientDataContext'

class AllTours extends Component {
  state = {
    tours: [],
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="all-tours pt-page">
        <div className="container">
          <h3>Browse Tours</h3>
          <div>
            <CardItemsContainer title="Our Tours List" baseLink="tour" data={this.props.tours} />
          </div>
        </div>
      </div>
    )
  }
}

export default ClientDataConsumerHOC(AllTours)
