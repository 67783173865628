import React from 'react'
import Slider from 'react-slick'
import _ from 'lodash'
import { Row, Col } from 'reactstrap'
import { ClientDataConsumerHOC } from '../../context/ClientDataContext'
import Loader from '../../partials/LoadingContainer'

const About = props => {
  const { welcomeMessages } = props
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  }
  return (
    <div className="article-detail pt-page">
      <div className="container">
        <Row className="article-content">
          <Col xs={12} md={9}>
            <h3>Selamat Datang - Herzlich Willkommen !</h3>
            <hr />
            {_.isArray(welcomeMessages) && welcomeMessages.length > 0 ? (
              <div className="py-4 mb-3">
                <Slider {...settings}>
                  {welcomeMessages.map((d, i) => (
                    <div key={i} className="slick-item">
                      <div className="img-wrapper">
                        <img className="img-fluid" src={d.src} alt={i} />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <Loader />
            )}
            <p>
              {`Ich heisse Elis. Ich bin die reiseleiterin! Mein Name ist Kadek Elis, genannt Elis.
              Ich begrüße Sie herzlich auf balitouren.de. Hier finden Sie einige Informationen rund
              um Bali, den Attraktionen & Sehenswürdigkeiten sowie einiger nützlichen Tip für Ihren
              Aufenthalt auf Bali. Durch meine langjährige Berufserfahrung verfüge ich über
              umfassende Kenntnisse zu den Sehenswürdikeiten, der Kunst und Kultur, sowie dem Land,
              den Leuten und der Hindu-Religion. Ich würde mich freuen Sie bei Ihrem Besuch auf Bali
              auf einem meiner Touren oder einer Tour ganz nach Ihren Wünschen begleiten zu dürfen.
              Ihr Elis`}
            </p>
          </Col>
          <Col xs={12} md={3}>
            <div id="holidaycheck-widget-poi-1-29727-de_DE-m-b-1-1-1-5-0">
              <div
                id="holidaycheck_widget_placeholder-29727"
                style={{ width: '120px', padding: '5px', background: '#0058a2' }}>
                <a href="https://www.holidaycheck.de/pi/guide-made-mardika-sanur/0e2f84d8-aea5-32b8-ba5f-c2cb30ef58bd">
                  <img
                    alt="Bewertungen bei HolidayCheck"
                    src="https://www.holidaycheck.de/widgets/logo_xlarge.png"
                    border="0"
                  />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ClientDataConsumerHOC(About)
