import React from 'react'
import { Link, withRouter } from 'react-router-dom'

const MenuItem = ({ mobile, toggle, activeMenu = '0', menuToggle }) => {
  return (
    <div
      className="d-flex flex-column menu-items"
      onClick={() => {
        mobile && toggle()
      }}>
      <Link
        onClick={() => menuToggle('1')}
        to="/admin/dashboard/tours"
        className={`bg-secondary-site p-2 my-1 ${activeMenu === '1' ? 'active-menu' : ''}`}>
        Tours
      </Link>
      <Link
        onClick={() => menuToggle('2')}
        to="/admin/dashboard/destinations"
        className={`bg-secondary-site p-2 my-1 ${activeMenu === '2' ? 'active-menu' : ''}`}>
        Destination
      </Link>
      <Link
        onClick={() => menuToggle('8')}
        to="/admin/dashboard/requests"
        className={`bg-secondary-site p-2 my-1 ${activeMenu === '8' ? 'active-menu' : ''}`}>
        Tour Request
      </Link>
      <Link
        onClick={() => menuToggle('3')}
        to="/admin/dashboard"
        className={`bg-secondary-site p-2 my-1 ${activeMenu === '3' ? 'active-menu' : ''}`}>
        Review
      </Link>
      <Link
        onClick={() => menuToggle('3')}
        to="/admin/gallery"
        className={`bg-secondary-site p-2 my-1 ${activeMenu === '3' ? 'active-menu' : ''}`}>
        Gallery
      </Link>
      <Link
        onClick={() => menuToggle('4')}
        to="/admin/dashboard"
        className={`bg-secondary-site p-2 my-1 ${activeMenu === '4' ? 'active-menu' : ''}`}>
        Account
      </Link>
      <Link
        onClick={() => menuToggle('5')}
        to="/admin/dashboard/welcome-message"
        className={`bg-secondary-site p-2 my-1 ${activeMenu === '5' ? 'active-menu' : ''}`}>
        Welcome Message
      </Link>
      <Link
        onClick={() => menuToggle('6')}
        to="/admin/dashboard/manage-type"
        className={`bg-secondary-site p-2 my-1 ${activeMenu === '6' ? 'active-menu' : ''}`}>
        Input Type Manager
      </Link>
      <Link
        onClick={() => menuToggle('7')}
        className={`bg-secondary-site p-2 my-1 ${activeMenu === '7' ? 'active-menu' : ''}`}
        to="/">
        Home
      </Link>
    </div>
  )
}

export default withRouter(MenuItem)
