import React, { Component } from 'react'
import { Button, FormGroup, Input, Label, Row, Col } from 'reactstrap'
import moment from 'moment'
import uuidv4 from 'uuid/v4'
import { fstore, storage } from '../../../../config/firebase-config'
import { OverlayLoader } from '../../partials/OverlayLoader'

class WelcomeMessageEditor extends Component {
  state = {
    isLoading: false,
    overlay: false,
    priority: 5,
    messageName: '',
    messageCaption: '',
    isShowed: true,
    bgImage: null,
    imgLink: null,
  }

  handleChange = e => {
    if (e.target.type === 'file' && e.target.files[0] && e.target.files[0].type.includes('image')) {
      this.setState({
        [e.target.name]: e.target.files[0],
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }
  }

  handleSubmit = () => {
    const {
      pageType,
      history,
      match: {
        params: { id },
      },
    } = this.props
    const { priority, messageName, messageCaption, isShowed, bgImage } = this.state
    const putData = {
      priority,
      messageName,
      messageCaption,
      isShowed,
      lastEdit: moment().format('MMMM Do YYYY, h:mm:ss a'),
    }

    this.setState({ isLoading: true })
    let docPointer = null
    let docId = null
    if (this.props.pageType === 'EDIT') {
      docPointer = fstore
        .collection('welcome-messages')
        .doc(id)
        .set(putData, { merge: true })
    } else {
      docPointer = fstore.collection('welcome-messages').add(putData)
    }

    //upload the image
    if (bgImage && bgImage.type.includes('image')) {
      docPointer
        .then(ref => {
          docId = pageType === 'EDIT' ? id : ref.id

          const imgName = `welcome-${docId}-${uuidv4()}.jpg`
          return storage.ref(`public/welcome-message/${imgName}`).put(bgImage)
        })
        .then(res => {
          const imgPath = res.ref.fullPath
          return fstore
            .collection('welcome-messages')
            .doc(docId)
            .update({ imgPath })
        })
        .then(() => {
          setTimeout(() => {
            history.push('/admin/dashboard/welcome-message')
          }, 1000)
        })
        .catch(err => {
          console.log(err.message)
        })
        .finally(() => {
          this.setState({ isLoading: false })
        })
    } else {
      docPointer
        .then(res => {
          setTimeout(() => {
            history.push('/admin/dashboard/welcome-message')
          }, 1000)
        })
        .catch(err => {
          console.log(err.message)
        })
        .finally(() => {
          this.setState({ isLoading: false })
        })
    }
  }

  componentDidMount() {
    const {
      pageType,
      match: {
        params: { id },
      },
    } = this.props
    if (pageType === 'EDIT') {
      this.setState({ overlay: true })
      fstore
        .collection('welcome-messages')
        .doc(id)
        .get()
        .then(snap => {
          const data = snap.data()
          return storage
            .ref(data.imgPath)
            .getDownloadURL()
            .then(imgLink => {
              return {
                priority: data.priority,
                messageName: data.messageName,
                messageCaption: data.messageCaption,
                isShowed: data.isShowed,
                imgLink,
              }
            })
        })
        .then(fetchedData => {
          this.setState({
            ...fetchedData,
          })
        })
        .finally(() => {
          this.setState({ overlay: false })
        })
    }
  }

  render() {
    return (
      <Row className="justify-content-center py-3">
        <Col lg="8">
          <OverlayLoader isShow={this.state.overlay} />
          <h3 className="text-center mx-4">{`${
            this.props.pageType === 'EDIT' ? 'Edit' : 'New'
          } Message`}</h3>
          <FormGroup>
            <Label for="priority">Priority Level</Label>
            <Input
              type="number"
              id="priority"
              name="priority"
              onChange={this.handleChange}
              value={this.state.priority}
            />
          </FormGroup>
          <FormGroup>
            <Label for="messageName">Display Message</Label>
            <Input
              type="select"
              id="messageName"
              name="messageName"
              onChange={this.handleChange}
              value={this.state.messageName}>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="messageName">Message Name</Label>
            <Input
              type="text"
              id="messageName"
              name="messageName"
              onChange={this.handleChange}
              value={this.state.messageName}
            />
          </FormGroup>
          <FormGroup>
            <Label for="messageCaption">Message Caption</Label>
            <Input
              type="textarea"
              id="messageCaption"
              name="messageCaption"
              onChange={this.handleChange}
              value={this.state.messageCaption}
            />
          </FormGroup>
          {this.state.imgLink && (
            <div className="current-background">
              <Label>Current Background</Label>
              <img className="d-block img-fluid mb-4" src={this.state.imgLink} alt="bg-welcome" />
            </div>
          )}
          {this.state.bgImage && (
            <div className="current-background">
              <hr />
              <Label>Preview Background</Label>
              <img
                className="d-block img-fluid mb-4"
                src={URL.createObjectURL(this.state.bgImage)}
                alt="bg-welcome"
              />
            </div>
          )}
          <FormGroup>
            <Label for="bgImage">Choose Background Image</Label>
            <Input type="file" id="bgImage" name="bgImage" onChange={this.handleChange} />
          </FormGroup>
          <Button
            disabled={this.state.isLoading}
            className="ml-auto site-primary on-white"
            onClick={this.handleSubmit}>
            Save
          </Button>
        </Col>
      </Row>
    )
  }
}

export default WelcomeMessageEditor
