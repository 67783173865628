import React, { Component } from 'react'
import { Table, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { fstore } from '../../../../config/firebase-config'

const DataRecord = ({ snap, index }) => {
  const { tourPriority, tourName, tourType, isPublished, lastEdit = '' } = snap.data()
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{tourName}</td>
      <td>{tourType}</td>
      <td>{tourPriority}</td>
      <td>{lastEdit}</td>
      <td>{isPublished ? 'Yes' : 'No'}</td>
      <td>
        <Link to={`/admin/dashboard/tours/edit/${snap.id}`}>
          <button className="btn site-link">Edit</button>
        </Link>
        <span className="mx-2">/</span>
        <button
          className="btn site-link"
          onClick={() => {
            const confirmDelete = window.confirm('Are you sure ?')
            if (confirmDelete) {
              snap.ref.delete()
            }
          }}>
          Delete
        </button>
      </td>
    </tr>
  )
}

class TourList extends Component {
  state = {
    tourSnaps: [],
  }
  componentDidMount() {
    const tourSnaps = []
    fstore
      .collection('tours')
      .get()
      .then(snaps => {
        console.log(snaps)
        snaps.forEach(snap => {
          tourSnaps.push(snap)
        })
        this.setState({ tourSnaps })
      })
      .catch(err => {
        console.log('Failed to fetch: ', err.message)
      })
  }

  render() {
    return (
      <div>
        <h3 className="text-center">List of Tours</h3>
        <div className="">
          <Link className="link-white" to="/admin/dashboard/tours/add">
            <Button className="btn site-primary on-white my-2">+New Tour</Button>
          </Link>
          <Table striped hover>
            <thead>
              <tr>
                <th>No</th>
                <th>Title</th>
                <th>Type</th>
                <th>Prior</th>
                <th>Edited</th>
                <th>Pub</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(this.state.tourSnaps) &&
                this.state.tourSnaps.length > 0 &&
                this.state.tourSnaps.map((snap, key) => (
                  <DataRecord snap={snap} index={key} key={key} />
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    )
  }
}

export default TourList
