import React from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import WOW from 'react-wow'

const cardTitle = 'Card Title To Be Displayed'

const CardItem = props => {
  return (
    <Col md="6" lg="4" className="card-item-wrapper">
      <Link to={`/${props.baseLink}/${props.id}`}>
        <WOW animation="fadeInUp" duration="700ms" delay={`${String(props.delay)}ms`}>
          <div className="card-item" style={{ backgroundImage: 'url(' + props.src + ')' }}>
            <div className="card-title">{props.title || cardTitle}</div>
          </div>
        </WOW>
      </Link>
    </Col>
  )
}

const CardContainer = props => {
  const { title = '', data = [], baseLink = '' } = props
  let delay = 0
  return (
    <Row className="cards-container">
      <Col sm="12">
        <h2>{title}</h2>
      </Col>
      {data.map((d, key) => {
        delay += 100
        return (
          <CardItem
            delay={delay}
            baseLink={baseLink}
            title={d.title}
            id={d.id}
            src={Array.isArray(d.images) && d.images.length > 0 ? d.images[0] : d.images}
            key={key}
          />
        )
      })}
    </Row>
  )
}

export default CardContainer
