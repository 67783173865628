import React, { Component } from 'react'
import { Table } from 'reactstrap'
import { fstore } from '../../../../config/firebase-config'
import _ from 'lodash'

class RequestLists extends Component {
  state = {
    requests: [],
  }
  componentDidMount() {
    fstore
      .collection('tourRequests')
      .get()
      .then(snapshots => {
        if (!snapshots.empty) {
          const requests = []
          snapshots.forEach(snap => {
            requests.push(snap.data())
          })
          this.setState({ requests })
        }
      })
  }

  render() {
    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Email</th>
              <th>Date</th>
              <th>Tour</th>
              <th>Message</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {_.isArray(this.state.requests) &&
              this.state.requests.length > 0 &&
              this.state.requests.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.fullName}</td>
                  <td>{data.email}</td>
                  <td>{data.timeStamp}</td>
                  <td>{data.selectedService}</td>
                  <td>{data.message}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default RequestLists
