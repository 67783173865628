import React from "react";
import cubeloader from "./../../../assets/loaders/cube-loader.svg";

const BlankPageLoader = () => {
  return (
    <div className="loader-page">
      <img src={cubeloader} alt="loading" />
    </div>
  );
};

export default BlankPageLoader;
