import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Form
} from "reactstrap";
import { withRouter } from "react-router-dom";

import { AuthConsumerHOC } from "../../context/AuthContext";
import Loading from './../partials/BlankPageLoader'

class LoginPage extends Component {
  state = {
    userEmail: "",
    userPassword: "",
    disableSignIn: false,
    errorMessage: ""
  };
  handleInput = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = () => {
    this.setState({ disableSignIn: true });
    const { signIn } = this.props;
    signIn(this.state.userEmail, this.state.userPassword)
      .then(res => {
        console.log(res, "You've been signed in");
      })
      .catch(err => this.handleLoginError(err))
      .finally(() => this.setState({ disableSignIn: false }));
  };

  componentDidMount() {
    if (this.props.currentUser && this.props.currentUser.uid) {
      console.log(this.props.currentUser, "currentUser");
      this.props.history.push("/admin/dashboard");
    }
  }

  handleLoginError(err) {
    this.setState({ errorMessage: err.message });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentUser !== null) {
      console.log("Login update triggered");
      this.props.history.push("/admin/dashboard");
    }
  }

  render() {
    return this.props.currentUser === null ? (
      <Container>
        <Row className="justify-content-center my-5">
          <Col xs={8} md={7} xl={6} className="py-3 border border-primary">
            <h2 className="text-center">Login</h2>
            <Form
              onSubmit={e => {
                e.preventDefault();
                this.handleSubmit();
              }}
            >
              <FormGroup>
                <Label for="userEmail">Username</Label>
                <Input
                  type="text"
                  name="userEmail"
                  id="userEmail"
                  placeholder="Username"
                  onChange={e => this.handleInput(e)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="userPassword">Password</Label>
                <Input
                  type="password"
                  name="userPassword"
                  id="userPassword"
                  placeholder="Password"
                  onChange={e => this.handleInput(e)}
                />
              </FormGroup>
              <Button
                disabled={this.state.disableSignIn}
                color="primary py-1 px-3 mr-2"
              >
                Login
              </Button>
              <Button
                color="secondary py-1 px-3 mr-2"
                onClick={() => window.location.replace("/")}
              >
                Cancel
              </Button>
            </Form>
            <div className="text-danger my-1">{this.state.errorMessage}</div>
          </Col>
        </Row>
      </Container>
    ) : (
      <Loading />
    );
  }
}

export default AuthConsumerHOC(withRouter(LoginPage));
