import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Table, Button } from 'reactstrap'
import { fstore } from '../../../../config/firebase-config'
import _ from 'lodash'

class WelcomeMessage extends Component {
  state = {
    messageData: [],
  }

  componentDidMount() {
    fstore
      .collection('welcome-messages')
      .get()
      .then(snapshots => {
        if (!snapshots.empty) {
          const messageData = []
          snapshots.forEach(snap => {
            const data = snap.data()
            messageData.push({
              priority: data.priority,
              name: data.messageName,
              caption: data.messageCaption,
              isShowed: data.isShowed,
              lastEdit: data.lastEdit,
              id: snap.id,
            })
          })
          this.setState({ messageData })
        }
      })
  }

  render() {
    return (
      <div>
        <h3 className="text-center mb-4">Welcome Message Manager</h3>
        <Link to="/admin/dashboard/welcome-message/new">
          <Button>+New Message</Button>
        </Link>
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>Prior</th>
              <th>Name</th>
              <th>Caption</th>
              <th>Show</th>
              <th>Edited</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(this.state.messageData) &&
              this.state.messageData.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.priority}</td>
                  <td>{data.name}</td>
                  <td>
                    {data.caption.length > 60 ? `${data.caption.slice(0, 60)}....` : data.caption}
                  </td>
                  <td>{data.isShowed ? 'Yes' : 'No'}</td>
                  <td>{data.lastEdit}</td>
                  <td>
                    <Link to={`/admin/dashboard/welcome-message/edit/${data.id}`}>
                      <button className="btn site-link">Edit</button>
                    </Link>
                    <span className="mx-2">/</span>
                    <button
                      className="btn site-link"
                      onClick={() => {
                        const confirmDelete = window.confirm('Are you sure ?')
                        if (confirmDelete) {
                          fstore
                            .collection('welcome-messages')
                            .doc(data.id)
                            .delete()
                            .then(() => {
                              _.remove(this.state.messageData, { id: data.id })
                              this.setState({ messageData: this.state.messageData })
                            })
                        }
                      }}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default WelcomeMessage
