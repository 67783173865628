import React, { Component } from 'react'
import { Button, FormGroup, Label, Input } from 'reactstrap'
import WOW from 'react-wow'
import moment from 'moment'
import _ from 'lodash'
import Recaptcha from 'react-recaptcha'

import { fstore } from '../../../config/firebase-config'
import { ClientDataConsumerHOC } from '../../context/ClientDataContext'

import Loader from '../../partials/LoadingContainer'

class BookTour extends Component {
  state = {
    isLoading: true,
    fullName: '',
    email: '',
    selectedServiceId: '',
    message: '',
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
    if (e.target.name === 'selectedServiceId') {
      const selectedService = () => {
        const filtered = _.filter(this.props.tours, { id: e.target.value })[0]
        return (filtered && filtered.title) || 'Custom Tour'
      }
      this.setState({ selectedService: selectedService() })
    }
  }

  handleSubmit = () => {
    this.setState({ isLoading: true })
    const { fullName, email, selectedServiceId, selectedService, message } = this.state
    const putData = {
      fullName,
      email,
      selectedServiceId,
      selectedService,
      message,
      timeStamp: moment().format('MMMM Do YYYY, h:mm:ss a'),
    }
    fstore
      .collection('tourRequests')
      .add(putData)
      .then(res => {
        console.log('Success send your tour request!')
        setTimeout(() => {
          this.props.history.push('/')
        }, 500)
      })
      .catch(err => {
        console.log('Failed to send your request:', err.message)
        this.setState({ isLoading: false })
      })
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return !this.props.finishedFetch ? (
      <div className="pt-page container">
        <Loader />
      </div>
    ) : (
      <WOW duration="500ms" animation="fadeInUp">
        <div className="book-page pt-page">
          <div className="container">
            <h2>Book Now</h2>
            <div>
              <FormGroup>
                <Label for="fullName">Full Name</Label>
                <Input
                  onChange={this.handleChange}
                  value={this.state.fullName}
                  type="text"
                  name="fullName"
                  id="fullName"
                  placeholder="Your full name here"
                />
              </FormGroup>
              <FormGroup>
                <Label for="bookerEmail">Email</Label>
                <Input
                  onChange={this.handleChange}
                  value={this.state.email}
                  type="email"
                  name="email"
                  id="bookerEmail"
                  placeholder="Type your email here"
                />
              </FormGroup>
              <FormGroup>
                <Label for="serviceSelect">Select Service</Label>
                <Input
                  onChange={this.handleChange}
                  value={this.state.selectedServiceId}
                  type="select"
                  name="selectedServiceId"
                  id="serviceSelect">
                  <option defaultValue hidden>
                    Select service you wish to order ...{' '}
                  </option>
                  {this.props.tours.map((tour, key) => {
                    return (
                      <option key={key} value={tour.id}>
                        {`${tour.type} - ${tour.title}`}
                      </option>
                    )
                  })}
                  <option value="Custom Request">I'd like to request a custom tour package</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="bookerMessage">Your message here:</Label>
                <Input
                  onChange={this.handleChange}
                  value={this.state.message}
                  type="textarea"
                  name="message"
                  id="bookerMessage"
                />
              </FormGroup>
              <Recaptcha
                sitekey="6LexCqoUAAAAAKci7MTye4GHVJmy-p865R994Fev"
                verifyCallback={() => this.setState({ isLoading: false })}
              />
              <Button
                disabled={this.state.isLoading}
                onClick={!this.state.isLoading && this.handleSubmit}
                className="site-primary on-white">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </WOW>
    )
  }
}

export default ClientDataConsumerHOC(BookTour)
