import React from 'react'
import LoaderIcon from '../../assets/loaders/elipsis-loader.svg'

const Loader = ({ size = '50px' }) => (
  <div className="d-flex flex-row justify-content-center">
    <img style={{ width: size, height: size }} src={LoaderIcon} alt="loader-elipsis" />
  </div>
)

export default Loader
