import React, { Component } from 'react'
import Header from './layouts/Header'
import Footer from './layouts/Footer'
import RouteList from './RouteList'
import { ClientDataProvider } from './context/ClientDataContext'

class Main extends Component {
  render() {
    return (
      <ClientDataProvider>
        <Header />
        <RouteList />
        <Footer />
      </ClientDataProvider>
    )
  }
}

export default Main
