import React, { Component } from 'react'
import { Row, Col, Button, FormGroup, FormText, Label, Input } from 'reactstrap'
import moment from 'moment'
import SimpleMDE from 'react-simplemde-editor'
import Select from 'react-select'
import uuidv4 from 'uuid/v4'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'

import { fstore, storage } from '../../../../config/firebase-config'
import { OverlayLoader } from '../../partials/OverlayLoader'
import { arrayBucketRefToDownloadLinks } from '../../../../helpers/Firebase-Helper'

const initialMarkerPosition = [-8.670633152513645, 114.8854922829196]

class DestinationEditor extends Component {
  mapRef = null
  markerRef = null

  state = {}
  state = {
    initialPosition: [-8.476447174280977, 115.22460937500001],
    markerPosition: initialMarkerPosition,
    isLoading: false,
    showOverlay: false,
    destinationName: '',
    articleData: '',
    selectedOptions: null,
    isPublished: true,
    fileImage: [], // array of type files
    destImagesBucket: [], // array of img REF to be uploaded
    tobeDeletedLinks: [], // array of img REF to be deleted
    imgLinks: [], // array of img LINKS
    labelList: [],
  }

  normalizeLabel = (array, normalize = true) => {
    const processedArray = []
    if (array)
      if (normalize)
        array.forEach(element => {
          processedArray.push(element.value)
        })
      else
        array.forEach(element => {
          processedArray.push({ value: element, label: element })
        })
    return processedArray
  }

  handleSubmitData = value => {
    this.setState({ isLoading: true })

    //set the data structure but not for the image links
    const { destinationName, articleData, isPublished, selectedOptions } = this.state
    const putData = {
      destName: destinationName,
      destDesc: articleData,
      isPublished: isPublished,
      label: this.normalizeLabel(selectedOptions),
      lastEdit: moment().format('MMMM Do YYYY, h:mm:ss a'),
      latlng: this.state.markerPosition,
    }

    const {
      match: {
        params: { id },
      },
    } = this.props

    const uploadPromises = []
    let docPointer = null
    let docId = null
    if (this.props.pageType === 'EDIT') {
      docPointer = fstore
        .collection('destinations')
        .doc(id)
        .set(putData)
    } else {
      docPointer = fstore.collection('destinations').add(putData)
    }

    //store the data to firestore
    docPointer
      .then(ref => {
        docId = ref ? ref.id : id

        //point to bucket reference folder based on firestore data
        const storageRef = storage.ref(`destinations/${docId}/`)

        //upload all images
        if (Array.isArray(this.state.fileImage) && this.state.fileImage.length > 0)
          this.state.fileImage.forEach(img => {
            console.log('Uploading file ...')
            const imgName = `article-${docId}-${uuidv4()}.jpg`
            const uploadTask = storageRef
              .child(imgName)
              .put(img)
              .then(res => {
                console.log('Success upload image: ', res)
                return storageRef.child(imgName).fullPath
              })
              .catch(err => console.log('Error upload individual file: ', err.message))
            uploadPromises.push(uploadTask)
          })
        return Promise.all(uploadPromises)
      })
      .then(refArray => {
        if (Array.isArray(this.state.fileImage) && this.state.fileImage.length > 0) {
          console.log('ALL NEW FILES HAVE BEEN UPLOADED! Promise all: ', refArray)
        } else console.log('No image need to be uploaded')

        //merge the img refPath with the newly added images
        if (Array.isArray(this.state.destImagesBucket)) {
          this.state.destImagesBucket.push(...refArray)
          this.setState({
            destImagesBucket: this.state.destImagesBucket,
          })
        } else {
          this.setState({
            destImagesBucket: refArray,
          })
        }

        // check if something need to be deleted from storage
        let tobeDeletedRef = []
        if (this.state.tobeDeletedLinks.length > 0) {
          console.log('Processing delete')
          this.state.tobeDeletedLinks.forEach(link => {
            const fullPath = storage.refFromURL(link).fullPath
            // push deleting task promises to array
            tobeDeletedRef.push(
              storage
                .refFromURL(link)
                .delete()
                .then(() => {
                  return fullPath
                })
            )
          })
          // execute the deleting batch from storage
          return Promise.all(tobeDeletedRef)
        }
      })
      .catch(err => {
        console.log('Promise upload all images failed: ', err.message)
      })
      .then(arrayOfDeletedRef => {
        if (!Array.isArray(arrayOfDeletedRef) || arrayOfDeletedRef.length <= 0) {
          console.log('Nothing to be deleted. Array: ', arrayOfDeletedRef)
        } else {
          console.log('Array of bucket to delete promise ', arrayOfDeletedRef)
          for (let i = 0; i < this.state.destImagesBucket.length; i++) {
            arrayOfDeletedRef.forEach(ref => {
              if (this.state.destImagesBucket[i] === ref) {
                this.state.destImagesBucket.splice(i, 1)
                i--
                console.log('MATCH DELETE', this.state.destImagesBucket)
              }
            })
          }
          this.setState({
            destImagesBucket: this.state.destImagesBucket,
          })
        }
      })
      .catch(err => {
        console.log('Failed batch delete ', err.message)
      })
      .then(res => {
        //update image path after success upload
        return fstore
          .collection('destinations')
          .doc(docId)
          .update({ destImages: this.state.destImagesBucket })
      })
      .catch(err => {
        console.log('Failed update fstore bucket array: ', err.message)
      })
      .then(res => {
        console.log('Success update images path: ', res)
        setTimeout(() => {
          this.props.history.push('/admin/dashboard/destinations')
        }, 2000)
      })
      .catch(err => {
        console.log('Failed update image path', err.message)
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  handleInputChange = e => {
    // console.log(e.target.name, e.target.value, e.target.type);
    if (e.target.type === 'text') {
      this.setState({ [e.target.name]: e.target.value })
    } else if (e.target.type === 'file') {
      this.setState({ [e.target.name]: Array.from(e.target.files) })
    }
  }

  handleSelectChange = selectedOptions => {
    this.setState({
      selectedOptions: selectedOptions,
    })
  }

  markerChange = e => {
    if (isNaN(+e.target.value)) return
    if (e.target.name === 'lat') {
      this.setState({ markerPosition: [e.target.value, this.state.markerPosition[1]] })
      this.markerRef.leafletElement.setLatLng([e.target.value, this.state.markerPosition[1]])
      this.mapRef.leafletElement.setView([e.target.value, this.state.markerPosition[1]], 10)
    } else if (e.target.name === 'lng') {
      this.setState({ markerPosition: [this.state.markerPosition[0], e.target.value] })
      this.markerRef.leafletElement.setLatLng([this.state.markerPosition[0], e.target.value])
      this.mapRef.leafletElement.setView([this.state.markerPosition[0], e.target.value], 10)
    }
  }

  componentDidMount() {
    fstore
      .collection('type')
      .doc('label')
      .get()
      .then(res => {
        this.setState({ labelList: this.normalizeLabel(res.data().data, false) })
      })
    const { pageType } = this.props
    if (pageType === 'ADD') {
      // this.fetchData(this.props.params);
    } else if (pageType === 'EDIT') {
      this.setState({ showOverlay: true })
      const {
        match: {
          params: { id: docId },
        },
      } = this.props

      // fetching data from firestore
      fstore
        .collection('destinations')
        .doc(docId)
        .get()
        .then(res => {
          const {
            destName,
            destDesc,
            label,
            isPublished,
            destImages,
            latlng = initialMarkerPosition,
          } = res.data()
          this.setState({
            destinationName: destName,
            articleData: destDesc,
            selectedOptions: this.normalizeLabel(label, false),
            isPublished,
            destImagesBucket: destImages,
            markerPosition: latlng,
          })

          //fetch all img download links
          arrayBucketRefToDownloadLinks(destImages).then(arr => {
            if (arr.length > 0) {
              for (let i = 0; i < arr.length; i++) {
                if (arr[i] === '') {
                  arr.splice(i, 1)
                  i--
                }
              }
            }
            this.setState({ imgLinks: arr })
          })
          console.log(res.data())
        })
        .catch(err => {
          console.log('Failed to fetch', err.message)
        })
        .finally(() => {
          this.setState({ showOverlay: false })
        })
    } else {
      console.log('Unknown Page Type')
    }
  }

  render() {
    return (
      <div className="destination-editor" id="destination-editor">
        <OverlayLoader isShow={this.state.showOverlay} />
        <h3 className="text-center">{`${
          this.props.pageType === 'EDIT' ? 'Edit' : 'New'
        } Destination`}</h3>
        <Row className="justify-content-center py-3">
          <Col lg="8">
            <FormGroup>
              <Label for="destinationName">Destination Name</Label>
              <Input
                onChange={this.handleInputChange}
                type="text"
                name="destinationName"
                id="destinationName"
                placeholder="Name of the destination"
                value={this.state.destinationName}
              />
            </FormGroup>
            <FormGroup>
              <Label for="destinationLabel">Destination Label</Label>
              <Select
                name="selectedOptions"
                options={this.state.labelList}
                onChange={this.handleSelectChange}
                value={this.state.selectedOptions}
                isMulti={true}
                placeholder="beach, forest, animal, ..."
              />
            </FormGroup>
            <FormGroup>
              <Label for="isPublished">Publish after save?</Label>
              <Input
                onChange={this.handleInputChange}
                name="isPublished"
                type="select"
                className="col-sm-2"
                value={this.state.isPublished}>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="imageInput">Destination Images</Label>
              <Input
                type="file"
                name="fileImage"
                id="imageInput"
                multiple
                onChange={this.handleInputChange}
              />
              <FormText color="muted">
                Select and upload your image here. You can upload up to 10 images, each image must
                not has size more than 3Mb.
              </FormText>
            </FormGroup>
            <div className="img-upload-preview d-flex flex-wrap">
              {this.state.fileImage.map((d, i) => {
                const url = URL.createObjectURL(d)
                return <img className="mr-2 mb-2" key={i} src={url} alt="be-uploaded" />
              })}
            </div>
            {this.props.pageType === 'EDIT' && this.state.imgLinks && (
              <div>
                <Label>Already Uploaded Images:</Label>
                <hr />
                <div className="img-upload-preview d-flex flex-wrap">
                  {this.state.imgLinks.map((img, key) => {
                    return (
                      <img
                        className="mr-2 mb-2 uploaded"
                        src={img}
                        key={key}
                        alt="uploaded"
                        onClick={() => {
                          //removing images from preview
                          for (let i = 0; i < this.state.imgLinks.length; i++) {
                            if (this.state.imgLinks[i] === img) {
                              this.state.imgLinks.splice(i, 1)
                              this.state.tobeDeletedLinks.push(img)
                              this.setState({
                                imgLinks: this.state.imgLinks,
                                tobeDeletedLinks: this.state.tobeDeletedLinks,
                              })
                            }
                          }
                        }}
                      />
                    )
                  })}
                </div>
              </div>
            )}

            <div className="mb-4">
              <div className="my-2">Drop Pin For This Location</div>
              <div className="d-flex latlng-input">
                <div className="d-flex align-items-center my-2">
                  <span className="mx-1">Latitude: </span>
                  <input
                    className="mx-1 "
                    name="lat"
                    type="text"
                    value={this.state.markerPosition[0]}
                    onChange={this.markerChange}
                  />
                  <span className="mx-1">Longtitude: </span>
                  <input
                    className="mx-1 "
                    name="lng"
                    type="text"
                    value={this.state.markerPosition[1]}
                    onChange={this.markerChange}
                  />
                </div>
              </div>
              <Map
                ref={ref => (this.mapRef = ref)}
                onclick={e => {
                  this.setState({ markerPosition: [e.latlng.lat, e.latlng.lng] })
                }}
                center={this.state.initialPosition}
                zoom={10}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker
                  ref={ref => (this.markerRef = ref)}
                  draggable
                  position={this.state.markerPosition}
                  ondragend={() => {
                    const { lat, lng } = this.markerRef.leafletElement.getLatLng()
                    this.setState({ markerPosition: [lat, lng] })
                  }}>
                  <Popup>
                    Kertalangu
                    <br />
                    09:00am - 10:30am
                  </Popup>
                </Marker>
              </Map>
            </div>

            <SimpleMDE
              id="destination-article-editor"
              label="Destination Article Editor"
              value={this.state.articleData}
              onChange={value => this.setState({ articleData: value })}
              options={{
                hideIcons: ['guide', 'fullscreen', 'side-by-side'],
              }}
            />
            <div className="d-flex">
              <div className="d-flex flex-column ml-auto my-3">
                <Button
                  onClick={this.handleSubmitData}
                  className="ml-auto site-primary on-white"
                  disabled={this.state.isLoading}>
                  Save
                </Button>
                {/* <div className="editor-error-message ml-auto">Error Message Here</div> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default DestinationEditor
