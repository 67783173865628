import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'

import DestinationDetail from './DestinationDetail'
import OtherDestinations from './OtherDestinations'
import { ClientDataConsumerHOC } from '../../context/ClientDataContext'
import { fstore, storage } from '../../../config/firebase-config'

const otherTour = [
  {
    tourTitle: 'Kintamani Full Day Tour',
    tourUrl: '/tours/b',
  },
  {
    tourTitle: 'Bedugul - Tabanan Outbond Tour',
    tourUrl: '/tours/b',
  },
  {
    tourTitle: 'Best Beach in Bali to Explore: Padang - padang Beach',
    tourUrl: '/tours/b',
  },
  {
    tourTitle: 'Sunrise Tour in Lovina Beach Buleleng',
    tourUrl: '/tours/b',
  },
  {
    tourTitle: 'Unlimited Balinese Art Discovery Tour in Ubud',
    tourUrl: '/tours/b',
  },
]

class DestinationDetailContainerPage extends Component {
  state = {
    destData: {},
  }

  componentDidMount() {
    console.log(this.props, 'Props dest')
    window.scrollTo(0, 0)
    const id = this.props.match.params.id
    if (Array.isArray(this.props.destinations) && this.props.destinations.length > 0) {
      console.log('PROPS AVAILABLE')
      const destData = this.props.destinations.filter(data => data.id === id)
      this.setState({ destData: destData[0] })
    } else {
      console.log('PROPS NOT AVAILABLE')
      fstore
        .collection('destinations')
        .doc(id)
        .get()
        .then(snap => {
          const data = snap.data()
          if (Array.isArray(data.destImages) && data.destImages.length > 0) {
            const imgPromises = data.destImages.map(img => {
              return storage.ref(img).getDownloadURL()
            })
            Promise.all(imgPromises).then(images => {
              const destData = {
                title: data.destName,
                desc: data.destDesc,
                isPublished: data.isPublished,
                images: images,
                label: data.label,
                latlng: data.latlng,
              }
              this.setState({ destData })
            })
          } else {
            const destData = {
              title: data.destName,
              desc: data.destDesc,
              isPublished: data.isPublished,
              images: [],
              label: data.label,
              latlng: data.latlng,
            }
            this.setState({ destData })
          }
        })
    }
  }

  render() {
    return (
      <div className="article-detail pt-page">
        <div className="container">
          <Row className="article-content">
            <Col xs={12} md={9}>
              <DestinationDetail
                data={this.state.destData}
                finishedFetch={this.props.finishedFetch}
              />
            </Col>
            <Col xs={12} md={3}>
              <OtherDestinations otherTour={otherTour} />
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default ClientDataConsumerHOC(DestinationDetailContainerPage)
