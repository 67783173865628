import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { AuthConsumerHOC } from "./../context/AuthContext";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./Dashboard";

const PrivateRoute = ({ currentUser, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return currentUser === null ? (
          <Redirect
            to={{
              pathname: "/admin",
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

const AdminMain = props => {
  return (
    <Switch>
      <PrivateRoute
        currentUser={props.currentUser}
        path="/admin/dashboard"
        component={Dashboard}
      />
      <Route component={LoginPage} />
    </Switch>
  );
};

export default AuthConsumerHOC(AdminMain);
