import React, { Component } from 'react'
import Slider from 'react-slick'
import WOW from 'react-wow'
import Loader from '../../partials/LoadingContainer'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'

class DestinationDetail extends Component {
  render() {
    const {
      data: { title = '', images = [], desc = '' } = {},
      finishedFetch = false,
      latlng = [-8.476447174280977, 115.22460937500001],
    } = this.props
    const settings = {
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return (
      <div className="">
        {!finishedFetch ? (
          <Loader />
        ) : (
          <>
            <WOW animation="fadeIn" duration="700ms" delay="300ms">
              <h3>{title}</h3>
              <hr />
            </WOW>
            <WOW animation="fadeIn" duration="700ms" delay="400ms">
              <Slider {...settings} className="mb-5">
                {images.map((d, i) => (
                  <div key={i} className="slick-item">
                    <div className="img-wrapper">
                      <img className="img-fluid" src={d} alt={i} />
                    </div>
                  </div>
                ))}
              </Slider>
              <hr />
            </WOW>
            <WOW animation="fadeIn" duration="700ms" delay="500ms">
              <div className="d-flex flex-column align-items-center mb-2">
                <Map className="client" center={latlng} zoom={10}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={latlng}>
                    <Popup>{title}</Popup>
                  </Marker>
                </Map>
              </div>
            </WOW>
            <WOW animation="fadeIn" duration="700ms" delay="600ms">
              <div className="article">
                <p>{desc}</p>
              </div>
            </WOW>
          </>
        )}
      </div>
    )
  }
}

export default DestinationDetail
